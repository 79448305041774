import { getConfig } from "./getConfig"

// PaymentGateway End-Points
const PaymentGateway = "Invoices"

const prefix = `/api/v1/client/bills`
const transactionsPrefix = `/api/v1/client/transactions`

const getBillsConfig = (action) => getConfig({ module: "Invoices", action })

export const getBillingsConfig = () => ({
    url: `${prefix}/getallinvoices`,
    config: getBillsConfig("View"),
})

export const createBillingConfig = () => ({
    url: `${prefix}/`,
    config: getBillsConfig("Create"),
})

export const updateBillingConfig = (id) => ({
    url: `${prefix}/${id}`,
    config: getBillsConfig("Update"),
})

export const deleteBillingConfig = (id) => ({
    url: `${prefix}/${id}`,
    config: getBillsConfig("Delete"),
})

export const getTransactionsConfig = () => ({
    url: `${transactionsPrefix}/search`,
    data: {
        advancedSearch: {
            fields: [],
            keyword: "",
        },
        keyword: "",
        pageNumber: 0,
        pageSize: 0,
        orderBy: ["CreatedOn"],
        // clientId: id
    },
    config: getBillsConfig("Search"),
})

export const listPaymentGatewaysConfig = () => ({
    url: `/api/v1/client/payment/list`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const getUserCardsConfig = () => ({
    url: `/api/v1/client/payment/cards`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const addCardConfig = () => ({
    url: `/api/v1/client/payment/add-card`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const payConfig = () => ({
    url: `/api/v1/client/payment/pay`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const getClientIdConfig = () => ({
    url: `/api/v1/client/payment/client-id`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const payPalCreateConfig = (amount, invoiceId) => ({
    url: `/api/v1/client/payment/create-order?amount=${amount}&invoiceId=${invoiceId}`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const payPalConfirmConfig = (orderId) => ({
    url: `/api/v1/client/payment/confirm-order?orderId=${orderId}`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const getInstructConfig = () => ({
    url: `/api/v1/client/payment/instruct`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const createTransferConfig = () => ({
    url: `/api/v1/client/payment/bank`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const approveTransferConfig = (id) => ({
    url: `/api/v1/client/payment/bank/${id}`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const getPayUrlConfig = () => ({
    url: `/api/v1/client/payment/pay-url`,
    config: getConfig({ module: PaymentGateway, action: "View" }),
})

export const cancelAgreeConfig = () => ({
    url: `/api/v1/client/payment/cancel-agreement`,
    config: getConfig({ module: PaymentGateway, action: 'Update' }),
})
